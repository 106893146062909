import { ModalHeader } from "../ModalHeader";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

export function OrganizationSettings() {
  const user = useUser();
  return (
    <>
      <ModalHeader />
      <div className="border-b border-gray-300 -mx-4 px-4 pb-4">
        <div className="font-bold">{user.membership.organization.name}</div>
      </div>
      <div className="flex flex-col -mx-4">
        <Link
          to="/organization-members"
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
        >
          Members
        </Link>
        <Link
          to="/change-organization-name"
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
        >
          Change name
        </Link>
        <Link
          to="/leave-organization"
          className="text-sm text-red-600 hover:bg-gray-100 px-4 py-2"
        >
          Leave organization
        </Link>
        <Link
          to="/delete-organization"
          className="text-sm text-red-600 hover:bg-gray-100 px-4 py-2"
        >
          Delete organization
        </Link>
      </div>
    </>
  );
}
