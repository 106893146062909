import { ModalHeader } from "../ModalHeader";
import { Button } from "../Button";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useSaas } from "../context/SaasContext";
import { useUser } from "../../hooks/useUser";

export function DeleteAccount() {
  const user = useUser();
  const { deleteAccount } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useSaas();

  async function submitHandler() {
    setIsSubmitting(true);
    try {
      await deleteAccount();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      toast.error(e.message, { id: "error" });
    }
  }

  return (
    <>
      <ModalHeader />
      <div className="font-bold">{user.email}</div>
      <div>
        Your account will be archived and deleted after 30 days. If you log in
        during this period the account will be restored automatically.
      </div>
      <Button
        classes="bg-red-600 text-white"
        onClick={submitHandler}
        isLoading={isSubmitting}
      >
        Delete account
      </Button>
    </>
  );
}
