import useSWR, { useSWRConfig } from "swr";
import { useApi } from "./useApi";
import { useUser } from "./useUser";
import { useSaas } from "../components/context/SaasContext";

export function useThings() {
  const api = useApi();
  const user = useUser();
  const { getSignedInUser } = useSaas();
  const API_URL = `${window.location.protocol}//${window.location.host}/api`;

  function list({ type, options }) {
    // When passing a function, SWR will use the return
    // value as `key`. If the function throws or returns
    // falsy, SWR will know that some dependencies are not
    // ready. In this case `user.id` throws when `user`
    // isn't loaded.
    const { data, error } = useSWR(() => {
      const url = new URL(`${API_URL}/things`);
      const opts = window.btoa(JSON.stringify(options));
      url.search = new URLSearchParams({
        type,
        opts,
        userId: user.id,
        membershipId: user.membership.id,
      });
      return {
        url: url.toString(),
      };
    }, api.getByUrl);
    return {
      things: data,
      isLoading: !error && !data,
      error,
    };
  }

  function get({ id, type, options = {} }) {
    const { data, error, mutate } = useSWR(() => {
      const url = new URL(`${API_URL}/things/${id}`);
      const opts = window.btoa(JSON.stringify(options));
      // If the function throws or returns a falsy value, SWR will not start the request
      url.search = new URLSearchParams({
        type,
        opts,
        userId: user.id,
        membershipId: user.membership.id,
      });
      return { url: url.toString() };
    }, api.getByUrl);
    return {
      thing: data,
      isLoading: !error && !data,
      error,
      mutate,
    };
  }

  async function save({ thing }) {
    const user = await getSignedInUser();
    if (!user) {
      return;
    }
    let savedThing;
    if (thing.id) {
      savedThing = await update({ thing });
    } else {
      savedThing = await create({ thing });
    }
    return savedThing;
  }

  function create({ thing }) {
    return api.post({ path: "/things", body: { thing } });
  }

  function update({ thing }) {
    return api.put({ path: `/things/${thing.id}`, body: { thing } });
  }

  function del({ thing }) {
    return api.del({ path: `/things/${thing.id}` });
  }

  return { list, save, get, del };
}
