const roundWithPrecision = (value, precision = 1) => {
  if (!value) {
    return 0;
  }
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const lerp = (x, y, a) => x * (1 - a) + y * a;
// lerp(20, 80, 0.5) // 40

const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
// clamp(32, 20, 30) // 30

const invlerp = (x, y, a) => clamp((a - x) / (y - x));
// invlerp(50, 100, 75)  // 0.5

const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));
// range(10, 100, 2000, 20000, 50) // 10000

const average = (nums) => nums.reduce((a, b) => a + b, 0) / nums.length;

const easeOutCubic = (x) => {
  return 1 - Math.pow(1 - x, 3);
};

const easeOutQuad = (x) => {
  return 1 - (1 - x) * (1 - x);
};

const easeInCubic = (x) => {
  return x * x * x;
};

const easeInQuad = (x) => {
  return x * x;
};

const weightedAverage = (nums, weights) => {
  const [sum, weightSum] = weights.reduce(
    (acc, w, i) => {
      acc[0] = acc[0] + nums[i] * w;
      acc[1] = acc[1] + w;
      return acc;
    },
    [0, 0]
  );
  return sum / weightSum;
};

export {
  roundWithPrecision,
  lerp,
  clamp,
  invlerp,
  range,
  average,
  weightedAverage,
  easeOutCubic,
  easeInCubic,
  easeOutQuad,
  easeInQuad,
};
