import { useSaas } from "./context/SaasContext";
import { ReactPortal } from "./ReactPortal";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { MarkdownParser } from "./MarkdownParser";

export function PopupArticle() {
  const { popupArticleUrl, hidePopupArticle } = useSaas();
  const nodeRef = useRef(null);

  // useEffect(() => {
  //   const closeOnEscapeKey = (e) => (e.key === "Escape" ? hideAbout() : null);
  //   document.body.addEventListener("keydown", closeOnEscapeKey);
  //   return () => {
  //     document.body.removeEventListener("keydown", closeOnEscapeKey);
  //   };
  // }, []);

  return (
    <ReactPortal wrapperId="react-portal-popup-article-container">
      <CSSTransition
        in={popupArticleUrl ? true : false}
        timeout={{ entry: 0, exit: 300 }}
        unmountOnExit
        classNames="modal"
        nodeRef={nodeRef}
      >
        <div
          className="modal fixed flex flex-col items-center justify-center inset-0"
          ref={nodeRef}
        >
          <button
            onClick={() => hidePopupArticle()}
            className="fixed inset-0 h-full w-full bg-black opacity-50 cursor-default"
          ></button>
          <div className="p-6 relative bg-white inset-0 rounded-lg flex flex-col justify-between h-full max-h-[90vh] w-full max-w-[850px] overflow-y-scroll">
            <div className="prose prose-sm">
              <MarkdownParser url={popupArticleUrl} />
            </div>
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
}
