import { useSaas } from "./context/SaasContext";
import { useUser } from "../hooks/useUser";

export function AboutButtons() {
  const user = useUser();
  const { hideAbout, showModal } = useSaas();
  function start() {
    hideAbout();
  }
  function login() {
    hideAbout();
    showModal();
  }
  return !user ? (
    <div className="flex items-center justify-center gap-2">
      <button className="button rounded-full w-36 bg-black" onClick={start}>
        Try now
      </button>
      <button
        className="button rounded-full w-36 bg-transparent border border-black text-black"
        onClick={login}
      >
        Log in
      </button>
    </div>
  ) : null;
}
