import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

export function MarkdownParser({ url }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return <ReactMarkdown children={content} />;
}
