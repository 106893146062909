import { useSaas } from "../context/SaasContext";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button } from "../Button";
import { PasswordInput } from "../PasswordInput";
import { EmailInput } from "../EmailInput";
import { useAuth } from "../../hooks/useAuth";
import { Error } from "../Error";

export function Login() {
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { hideModal } = useSaas();
  const { login } = useAuth();

  async function submitHandler(e) {
    try {
      setError(null);
      setIsLoading(true);
      const { user } = await login({
        email,
        password,
        remember: isRememberMe,
      });
      setIsLoading(false);
      hideModal({ result: user });
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Error error={error} />
      <div className="font-bold">Log in</div>
      <EmailInput
        autoFocus
        placeholder="Email address"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <PasswordInput
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      <div className="flex gap-2 items-center">
        <input
          checked={isRememberMe}
          onChange={(e) => setIsRememberMe(!isRememberMe)}
          id="remember"
          type="checkbox"
        />
        <label htmlFor="remember" className="text-sm text-gray-500">
          Remember me (using cookies)
        </label>
      </div>
      <Button isLoading={isLoading} onClick={submitHandler}>
        Log in
      </Button>
      <div className="flex flex-col -mx-4">
        <Link
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
          to="/forgot-password"
        >
          Forgot password
        </Link>
        <Link
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
          to="/signup"
        >
          Sign up
        </Link>
      </div>
    </>
  );
}
