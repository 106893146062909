import { ModalHeader } from "../ModalHeader";
import { Button } from "../Button";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useSaas } from "../context/SaasContext";
import { useUser } from "../../hooks/useUser";

export function DeleteOrganization() {
  const user = useUser();
  const { deleteOrganization } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useSaas();

  async function submitHandler() {
    try {
      if (confirm("Are you really sure you want to do this?")) {
        setIsSubmitting(true);
        await deleteOrganization();
        setIsSubmitting(false);
      }
    } catch (e) {
      setIsSubmitting(false);
      toast.error(e.message, { id: "error" });
    }
  }

  return (
    <>
      <ModalHeader />
      <div className="font-bold">{user.membership.organization.name}</div>
      <div>
        Your organization will be archived and permanently deleted after 30
        days.
      </div>
      <Button
        classes="bg-red-600 text-white"
        onClick={submitHandler}
        isLoading={isSubmitting}
      >
        Delete organization
      </Button>
    </>
  );
}
