import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../Button";
import { ModalHeader } from "../ModalHeader";
import { useSaas } from "../context/SaasContext";
import { PasswordInput } from "../PasswordInput";

export function ChangeUserPassword() {
  const { toast } = useSaas();
  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const { updateUserPassword } = useAuth();

  async function submitHandler(e) {
    try {
      setIsLoading(true);
      await updateUserPassword({
        oldPassword,
        newPassword,
        newPassword2,
      });
      setIsLoading(false);
      toast.success("Password changed!", { id: "save" });
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "save" });
    }
  }

  return (
    <>
      <ModalHeader />
      <div className="font-bold">Change password</div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">Old password</label>
        <PasswordInput
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">New password</label>
        <PasswordInput
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">Repeat new password</label>
        <PasswordInput
          value={newPassword2}
          onChange={(e) => setNewPassword2(e.target.value)}
        />
      </div>
      <Button isLoading={isLoading} onClick={submitHandler}>
        Save changes
      </Button>
    </>
  );
}
