import { Estimates } from "./pages/Estimates";
import { EstimatePage } from "./pages/EstimatePage";
import { Index } from "./pages/Index";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import { useUser, UserButton } from "@ymse/saas";
import ReactTooltip from "react-tooltip";
import { useSaas } from "@ymse/saas";

function Nav() {
  const user = useUser();
  if (!user) {
    return null;
  }
  return (
    <div className="flex gap-4 items-center">
      <Link to="/dashboard" className="hover:underline">
        Dashboard
      </Link>
    </div>
  );
}

function AppRoutes() {
  const user = useUser();
  return user ? (
    <Routes>
      <Route path="/" exact element={<Index />} />
      <Route path="/dashboard" exact element={<Estimates />} />
      <Route path="/estimate/:id" exact element={<EstimatePage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" exact element={<Index />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export function App() {
  const { showAbout } = useSaas();
  return (
    <Router>
      <div className="h-screen flex flex-col bg-white font-ui">
        <header className="flex items-center justify-between p-2 px-4 border-b border-gray-300">
          <h1 className="font-extrabold text-lg">
            <Link to="/">
              Calestimate <sup className="font-normal">beta</sup>
            </Link>
          </h1>
          <div className="flex gap-4 items-center">
            <Nav />
            <UserButton />
          </div>
        </header>
        <main className="flex-1 p-4 flex">
          <AppRoutes />
        </main>
        <footer className="p-4 flex items-center justify-between text-gray-400 text-sm">
          <div>
            <button onClick={(e) => showAbout()}>
              &copy; 2022 Calestimate
            </button>
          </div>
          <div>
            <button onClick={(e) => showAbout()}>About</button>
          </div>
        </footer>
      </div>
      <ReactTooltip />
    </Router>
  );
}
