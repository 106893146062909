import { Spinner } from "./Spinner";

export function Button({
  isDisabled = false,
  isLoading,
  classes = "",
  children,
  ...rest
}) {
  return (
    <button
      disabled={isDisabled || isLoading}
      className={`button ${classes}`}
      {...rest}
    >
      <div className="flex gap-2 justify-center items-center">
        {isLoading && <Spinner className="h-4 w-4 text-white" />}
        <div>{children}</div>
      </div>
    </button>
  );
}
