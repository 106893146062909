import { useSaas } from "./context/SaasContext";

export function ModalClose() {
  const { hideModal } = useSaas();
  return (
    <button
      className="hover:bg-gray-200 -m-2 p-2 rounded-full"
      onClick={(e) => hideModal({ result: null })}
    >
      <svg
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
      >
        <path d="M1.5 1.5l12 12m-12 0l12-12" stroke="currentColor"></path>
      </svg>
    </button>
  );
}
