import { useApi } from "./useApi";
import { useSaas } from "../components/context/SaasContext";

export function useAuth() {
  const { session, setSession } = useSaas();
  const api = useApi();

  async function setSignupEmail({ email }) {
    const json = await api.post({
      path: `/auth/set-signup-email`,
      body: { email },
    });
    return json;
  }

  async function sendEmailCode({ email }) {
    const json = await api.post({
      path: `/auth/send-email-code`,
      body: { email },
    });
    return json;
  }

  async function verifyCode({ token, code }) {
    const json = await api.post({
      path: `/auth/verify-code`,
      body: { token, code },
    });
    return json;
  }

  async function forgotPassword({ email }) {
    const json = await api.post({
      path: `/auth/forgot-password`,
      body: { email },
    });
    return json;
  }

  async function changePassword({ code, token, password, password2 }) {
    const json = await api.post({
      path: `/auth/change-password`,
      body: { code, token, password, password2 },
    });
    return json;
  }

  async function logout() {
    await api.post({
      path: "/auth/logout",
    });
    setSession(null);
    // reload/refresh page
    window.location.href = "/";
  }

  async function login({ email, password, remember }) {
    const json = await api.post({
      path: "/auth/login",
      body: { email, password, remember },
    });
    setSession(json);
    return json;
  }

  async function createOrganization({ name }) {
    const json = await api.post({
      path: `/auth/create-organization`,
      body: { name },
    });
    setSession(json);
    return json;
  }

  async function setOrganization({ organization }) {
    const json = await api.post({
      path: "/auth/set-organization",
      body: { organizationId: organization.id },
    });
    setSession(json);
    return json;
  }

  async function getInviteOrganizationToken() {
    const json = await api.get({
      path: "/auth/invite-organization-token",
    });
    return json;
  }

  async function validateInviteOrganizationToken({ token }) {
    const json = await api.post({
      path: "/auth/validate-invite-organization-token",
      body: { token },
    });
    return json;
  }

  async function joinOrganization({ token }) {
    const json = await api.post({
      path: "/auth/join-organization",
      body: { token },
    });
    setSession(json);
    return json;
  }

  async function getOrganizationMemberships() {
    const json = await api.get({
      path: "/auth/get-organization-memberships",
    });
    return json;
  }

  async function updateUserName({ firstName, lastName }) {
    const json = await api.post({
      path: "/auth/update-user-name",
      body: { firstName, lastName },
    });
    setSession(json);
    return json;
  }

  async function updateUserEmail({ token, code }) {
    const json = await api.post({
      path: "/auth/update-user-email",
      body: { token, code },
    });
    setSession(json);
    return json;
  }

  async function updateOrganizationName({ name }) {
    const json = await api.post({
      path: "/auth/update-organization-name",
      body: { name },
    });
    setSession(json);
    return json;
  }

  async function updateUserPassword({
    oldPassword,
    newPassword,
    newPassword2,
  }) {
    const json = await api.post({
      path: "/auth/update-user-password",
      body: { oldPassword, newPassword, newPassword2 },
    });
    return json;
  }

  async function deleteAccount() {
    await api.post({
      path: "/auth/delete-account",
    });
    setSession(null);
    // reload/refresh page
    window.location.href = "/";
  }

  async function deleteOrganization() {
    const json = await api.post({
      path: "/auth/delete-organization",
    });
    setSession(json);
  }

  async function leaveOrganization() {
    const json = await api.post({
      path: "/auth/leave-organization",
    });
    setSession(json);
  }

  async function finalizeSignup({
    code,
    token,
    password,
    password2,
    firstName,
    lastName,
    inviteToken,
  }) {
    const json = await api.post({
      path: `/auth/finalize-signup`,
      body: {
        code,
        token,
        firstName,
        lastName,
        password,
        password2,
        inviteToken,
      },
    });
    return json;
  }

  return {
    logout,
    login,
    verifyCode,
    finalizeSignup,
    forgotPassword,
    sendEmailCode,
    setSignupEmail,
    changePassword,
    createOrganization,
    setOrganization,
    getOrganizationMemberships,
    getInviteOrganizationToken,
    validateInviteOrganizationToken,
    joinOrganization,
    updateUserName,
    updateUserEmail,
    updateOrganizationName,
    updateUserPassword,
    deleteAccount,
    deleteOrganization,
    leaveOrganization,
  };
}
