import { useSaas } from "../context/SaasContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { TextInput } from "../TextInput";
import { Button } from "../Button";
import { useAuth } from "../../hooks/useAuth";
import { ModalHeader } from "../ModalHeader";

export function CreateOrganization() {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { toast } = useSaas();
  const { createOrganization } = useAuth();

  async function submitHandler(e) {
    try {
      setIsLoading(true);
      const result = await createOrganization({
        name,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "login" });
    }
  }

  return (
    <>
      <ModalHeader />
      <div className="font-bold">Create organization</div>
      <div>
        Organizations are paid per-user subscriptions where you can invite other
        users as team members.
      </div>
      <TextInput
        autoFocus
        placeholder="Organization name..."
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <Button isLoading={isLoading} onClick={submitHandler}>
        Create
      </Button>
    </>
  );
}
