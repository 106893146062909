export function useApi() {
  const API_URL = `${window.location.origin}/api`;
  const APP_ORIGIN = window.location.origin;

  function responseHandler(res) {
    if (!res.ok) {
      return res.json().then((json) => {
        throw new Error(json.message || json.error);
      });
    }
    return res.json();
  }

  async function get({ path, params = {}, headers = {} }) {
    const url = new URL(`${API_URL}${path}`);
    url.search = new URLSearchParams(params);
    return fetch(url.toString(), {
      headers: {
        "x-ymse-origin": APP_ORIGIN,
        ...headers,
      },
      method: "GET",
      credentials: "include",
    }).then(responseHandler);
  }

  async function getByUrl({ url, foo, headers = {} }) {
    return fetch(url, {
      headers: {
        "x-ymse-origin": APP_ORIGIN,
        ...headers,
      },
      method: "GET",
      credentials: "include",
    }).then(responseHandler);
  }

  async function post({ path, params = {}, body = {}, headers = {} }) {
    const url = new URL(`${API_URL}${path}`);
    url.search = new URLSearchParams(params);
    return fetch(url.toString(), {
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "x-ymse-origin": APP_ORIGIN,
        ...headers,
      },
      method: "POST",
      credentials: "include",
    }).then(responseHandler);
  }

  async function put({ path, params = {}, body, headers = {} }) {
    const url = new URL(`${API_URL}${path}`);
    url.search = new URLSearchParams(params);
    return fetch(url.toString(), {
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "x-ymse-origin": APP_ORIGIN,
        ...headers,
      },
      method: "PUT",
      credentials: "include",
    }).then(responseHandler);
  }

  async function del({ path, params = {}, headers = {} }) {
    const url = new URL(`${API_URL}${path}`);
    url.search = new URLSearchParams(params);
    return fetch(url.toString(), {
      headers: {
        "x-ymse-origin": APP_ORIGIN,
        ...headers,
      },
      method: "DELETE",
      credentials: "include",
    }).then(responseHandler);
  }

  return { post, getByUrl, put, del, get };
}
