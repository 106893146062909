import { useUser } from "../../hooks/useUser";
import { Button } from "../Button";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { ModalHeader } from "../ModalHeader";

export function Profile() {
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useAuth();
  const user = useUser();

  async function submitHandler(e) {
    try {
      setIsLoading(true);
      await logout();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      alert("Could not log you out. Please try again");
    }
  }

  return (
    <>
      <ModalHeader back={false} />
      <div className="flex justify-between items-center">
        <div>
          <div className="font-bold">
            {user.firstName} {user.lastName}
          </div>
          <div className="text-gray-600">{user.email}</div>
        </div>
        <div>
          <Link to="/user-settings" className="text-gray-500">
            <svg
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
            >
              <path
                clipRule="evenodd"
                d="M5.944.5l-.086.437-.329 1.598a5.52 5.52 0 00-1.434.823L2.487 2.82l-.432-.133-.224.385L.724 4.923.5 5.31l.328.287 1.244 1.058c-.045.277-.103.55-.103.841 0 .291.058.565.103.842L.828 9.395.5 9.682l.224.386 1.107 1.85.224.387.432-.135 1.608-.537c.431.338.908.622 1.434.823l.329 1.598.086.437h3.111l.087-.437.328-1.598a5.524 5.524 0 001.434-.823l1.608.537.432.135.225-.386 1.106-1.851.225-.386-.329-.287-1.244-1.058c.046-.277.103-.55.103-.842 0-.29-.057-.564-.103-.841l1.244-1.058.329-.287-.225-.386-1.106-1.85-.225-.386-.432.134-1.608.537a5.52 5.52 0 00-1.434-.823L9.142.937 9.055.5H5.944z"
                stroke="currentColor"
                strokeLinecap="square"
                strokeLinejoin="round"
              ></path>
              <path
                clipRule="evenodd"
                d="M9.5 7.495a2 2 0 01-4 0 2 2 0 014 0z"
                stroke="currentColor"
                strokeLinecap="square"
                strokeLinejoin="round"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
      <Link to="/select-organization">
        <div className="flex hover:bg-gray-100 items-center justify-between rounded border-gray-300 border p-2">
          <div>{user.membership.organization.name}</div>
          <svg
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
          >
            <path
              d="M14 5l-6.5 7L1 5"
              stroke="currentColor"
              strokeLinecap="square"
            ></path>
          </svg>
        </div>
      </Link>
      <Button isLoading={isLoading} onClick={submitHandler}>
        Sign out
      </Button>
    </>
  );
}
