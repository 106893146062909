import { useSaas } from "../context/SaasContext";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../Button";
import { TextInput } from "../TextInput";
import { PasswordInput } from "../PasswordInput";

export function SignupFinalize() {
  const [isLoading, setIsLoading] = useState(false);
  const { toast, token, code } = useSaas();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const { finalizeSignup } = useAuth();
  const navigate = useNavigate();

  async function submitHandler(e) {
    try {
      setIsLoading(true);
      await finalizeSignup({
        token,
        code,
        firstName,
        lastName,
        password,
        password2,
        inviteToken: localStorage.getItem("inviteToken"),
      });
      setIsLoading(false);
      toast.success("Account created! Please log in", { id: "success" });
      navigate("/login");
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "signup-finalize" });
    }
  }

  return (
    <>
      <div className="font-bold">Sign up</div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">First name</label>
        <TextInput
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">Last name</label>
        <TextInput
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">Password</label>
        <PasswordInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">Repeat password</label>
        <PasswordInput
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </div>
      <Button isLoading={isLoading} onClick={submitHandler}>
        Create account
      </Button>
      <Link className="underline text-sm" to="/login">
        Log in
      </Link>
    </>
  );
}
