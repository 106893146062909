export function CalcValue({ children }) {
  if (children === undefined) {
    return <div>--</div>;
  }
  return (
    <div
      className={`whitespace-nowrap h-8 w-full text-center flex items-center justify-center gap-2 text-sm`}
    >
      {children}
    </div>
  );
}
