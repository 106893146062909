import { ModalHeader } from "../ModalHeader";
import { Button } from "../Button";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useSaas } from "../context/SaasContext";
import { useUser } from "../../hooks/useUser";

export function LeaveOrganization() {
  const user = useUser();
  const { leaveOrganization } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useSaas();

  async function submitHandler() {
    try {
      if (confirm("Are you really sure you want to do this?")) {
        await leaveOrganization();
        setIsSubmitting(false);
      }
    } catch (e) {
      setIsSubmitting(false);
      toast.error(e.message, { id: "error" });
    }
  }

  return (
    <>
      <ModalHeader />
      <div className="font-bold">{user.membership.organization.name}</div>
      <div>
        Your membership will be archived and deleted permanently after 30 days.
      </div>
      <Button
        classes="bg-red-600 text-white"
        onClick={submitHandler}
        isLoading={isSubmitting}
      >
        Leave organization
      </Button>
    </>
  );
}
