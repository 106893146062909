import { useContext, createContext, useState, useEffect, useRef } from "react";
import { FullScreenSpinner } from "../FullScreenSpinner";
import pWaitFor from "p-wait-for";
import toast from "react-hot-toast";
import { useApi } from "../../hooks/useApi";

const SaasContext = createContext();

export const useSaas = () => {
  return useContext(SaasContext);
};

export const SaasProvider = ({
  children,
  AboutComponent,
  termsUrl,
  privacyUrl,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [popupArticleUrl, setPopupArticleUrl] = useState(null);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [token, setToken] = useState("");
  const [session, setSession] = useState(null);
  const isModalOpenRef = useRef(false);
  const modalResultRef = useRef(null);

  const api = useApi();

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      try {
        const json = await api.get({
          path: "/auth/session",
        });
        setSession(json);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setSession(null);
        setIsLoading(false);
      }
    }
    load();
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
    }
  }, []);

  // // add and load paddle script
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   //  scripts that are dynamically created have async set to true by default
  //   script.async = false;
  //   script.setAttribute("src", "https://cdn.paddle.com/paddle/paddle.js");
  //   script.addEventListener("load", function () {
  //     console.log("script loaded", Paddle);
  //     Paddle.Environment.set("sandbox");
  //     Paddle.Setup({ vendor: 9131 });
  //     Paddle.Checkout.open({ product: 38966 });
  //   });
  //   document.head.appendChild(script);
  // }, []);

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  async function showModal() {
    modalResultRef.current = null;
    isModalOpenRef.current = true;
    setIsModalOpen(true);
  }

  async function hideModal({ result = null }) {
    modalResultRef.current = result;
    isModalOpenRef.current = false;
    setIsModalOpen(false);
  }

  async function showAbout() {
    setIsAboutOpen(true);
  }

  async function hideAbout() {
    setIsAboutOpen(false);
  }

  async function getSignedInUser() {
    if (session?.user) {
      return session?.user;
    }
    if (
      !confirm(
        "You must be logged in to do this. Please log in or create a free account to continue."
      )
    ) {
      return null;
    }
    // open modal with route index 0
    showModal();
    // wait until modal closes
    await pWaitFor(() => !isModalOpenRef.current);
    // return with modal result, which is the user in this case, or undefined if user aborts.
    return modalResultRef.current;
  }

  function showPopupArticle(url) {
    setPopupArticleUrl(url);
  }

  function hidePopupArticle() {
    setPopupArticleUrl(null);
  }

  return (
    <SaasContext.Provider
      value={{
        toast,
        isModalOpen,
        showModal,
        hideModal,
        user: session?.user,
        getSignedInUser,
        token,
        setToken,
        email,
        setEmail,
        code,
        setCode,
        session,
        setSession,
        AboutComponent,
        isAboutOpen,
        setIsAboutOpen,
        showAbout,
        hideAbout,
        termsUrl,
        privacyUrl,
        showPopupArticle,
        hidePopupArticle,
        popupArticleUrl,
      }}
    >
      {children}
    </SaasContext.Provider>
  );
};
