import { useSaas } from "./context/SaasContext";
import { ReactPortal } from "./ReactPortal";
import { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useUser } from "../hooks/useUser";

export function About() {
  const user = useUser();
  const { AboutComponent, isAboutOpen, showAbout, hideAbout } = useSaas();
  const nodeRef = useRef(null);

  // display on "/" page load
  useEffect(() => {
    setTimeout(() => {
      if (!user && window.location.pathname === "/") {
        showAbout(true);
      }
    }, 200);
  }, []);

  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? hideAbout() : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, []);

  if (!AboutComponent) {
    return null;
  }

  return (
    <ReactPortal wrapperId="react-portal-about-container">
      <CSSTransition
        in={isAboutOpen}
        timeout={{ entry: 0, exit: 300 }}
        unmountOnExit
        classNames="modal"
        nodeRef={nodeRef}
      >
        <div className="modal" ref={nodeRef}>
          <button
            onClick={() => hideAbout()}
            className="fixed inset-0 h-full w-full cursor-default bg-black opacity-30"
          ></button>
          <div className="fixed right-0 top-0 h-screen bg-white flex text-black shadow-xl w-96 overflow-y-scroll">
            <AboutComponent />
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
}
