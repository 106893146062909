import { ModalClose } from "./ModalClose";
import { ModalBack } from "./ModalBack";

export function ModalHeader({ back = true }) {
  return (
    <div className="flex items-center justify-between -mx-4 px-4 pb-2 border-b border-gray-300">
      <ModalBack disabled={!back} />
      <ModalClose />
    </div>
  );
}
