import { createRoot } from "react-dom/client";
import { App } from "./components/App";
import { SaasProvider } from "@ymse/saas";
import { About } from "./components/About";

const root = createRoot(document.getElementById("app"));
root.render(
  <SaasProvider
    AboutComponent={About}
    privacyUrl="https://s3.eu-central-1.wasabisys.com/ymse-public/apps/app_FnaZaBSeP2MlZt8G/docs/privacy-policy.md"
  >
    <App />
  </SaasProvider>
);
