import { useAuth } from "../hooks/useAuth";
import { useUser } from "../hooks/useUser";
import { useState } from "react";
import { Spinner } from "./Spinner";

export function SelectOrganizationButton({ organization }) {
  const [isLoading, setIsLoading] = useState(false);
  const user = useUser();
  const { setOrganization } = useAuth();
  async function setOrganizationHandler(e) {
    setIsLoading(true);
    await setOrganization({ organization });
    setIsLoading(false);
  }
  return (
    <button onClick={setOrganizationHandler} className="hover:bg-gray-50">
      <div className="flex items-center justify-between rounded border-gray-300 border p-2 bg-white">
        <div>{organization.name}</div>
        <div>
          {organization.id === user.membership.organization.id ? (
            <svg
              className="text-green-600"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm7.072 3.21l4.318-5.398-.78-.624-3.682 4.601L4.32 7.116l-.64.768 3.392 2.827z"
                fill="currentColor"
              ></path>
            </svg>
          ) : isLoading ? (
            <Spinner className="h-5 w-5 text-green-500" />
          ) : null}
        </div>
      </div>
    </button>
  );
}
