import { useSaas } from "../context/SaasContext";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../Button";
import { EmailInput } from "../EmailInput";

export function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { toast, setToken, setCode } = useSaas();
  const { forgotPassword } = useAuth();

  async function submitHandler(e) {
    try {
      setIsLoading(true);
      const { token } = await forgotPassword({
        email,
      });
      setIsLoading(false);
      setToken(token);
      setCode("");
      navigate("/verify-forgot-password");
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "login" });
    }
  }

  return (
    <>
      <div className="font-bold">Change password</div>
      <EmailInput
        autoFocus
        placeholder="Your email address"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <Button isLoading={isLoading} onClick={submitHandler}>
        Continue
      </Button>
      <div className="flex flex-col -mx-4">
        <Link
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
          to="/login"
        >
          Log in
        </Link>
      </div>
    </>
  );
}
