import { Estimate } from "../Estimate";
import { useParams } from "react-router-dom";
import { useThings, FullScreenSpinner } from "@ymse/saas";

export function EstimatePage() {
  const { id } = useParams();
  const { get: getThing } = useThings();
  const {
    thing: estimate,
    isLoading,
    error,
    mutate,
  } = getThing({ id, type: "estimate" });

  console.log({ id, error, isLoading });

  if (error) {
    return;
  }

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  return <Estimate mutate={mutate} initialEstimate={estimate} />;
}
