import { useUser } from "../../hooks/useUser";
import { Link } from "react-router-dom";
import { SelectOrganizationButton } from "../SelectOrganizationButton";
import { ModalHeader } from "../ModalHeader";

export function SelectOrganization() {
  const user = useUser();
  return (
    <>
      <ModalHeader />
      <div className="font-bold">Select organization</div>
      <div className="flex flex-col gap-2">
        {user.memberships.map((membership) => (
          <SelectOrganizationButton
            key={membership.organization.id}
            organization={membership.organization}
          />
        ))}
      </div>
      <div className="flex flex-col -mx-4">
        <Link
          to="/organization-settings"
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
        >
          Settings
        </Link>
        <Link
          to="/join-organization"
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
        >
          Join new organization
        </Link>
        <Link
          to="/create-organization"
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
        >
          Create organization
        </Link>
      </div>
    </>
  );
}
