import { roundWithPrecision } from "../../lib/math";

export function Result({ data }) {
  if (!data) {
    return null;
  }
  return (
    <div className="flex flex-col items-center gap-5 bg-gray-50 py-4 border border-gray-500">
      <div className="font-bold text-xl">Estimate</div>
      <div className="flex justify-center gap-20">
        <div className="flex flex-col items-center">
          <div className="text-sm">Hours</div>
          <div className="text-2xl font-bold">
            {roundWithPrecision(data.estimatedHours)}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-sm">Days</div>
          <div className="text-2xl font-bold">
            {roundWithPrecision(data.estimatedHours / 8)}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-sm">Weeks</div>
          <div className="text-2xl font-bold">
            {roundWithPrecision(data.estimatedHours / 40)}
          </div>
        </div>
      </div>
      <div className="text-xs text-gray-500">
        There's a 95% probability that the project will be completed within this
        period.
      </div>
    </div>
  );
}
