import { useSaas } from "../context/SaasContext";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import VerificationInput from "react-verification-input";
import { useAuth } from "../../hooks/useAuth";
import { Error } from "../Error";
import { useState } from "react";

export function VerifyChangeUserEmail() {
  const { token, toast, code, setCode, hideModal } = useSaas();
  const { updateUserEmail } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    async function load() {
      if (code.length === 6) {
        try {
          await updateUserEmail({
            token,
            code,
          });
          toast.success("Your email is now changed", { id: "verify" });
          hideModal({ result: null });
        } catch (e) {
          setCode("");
          setError(e);
        }
      }
    }
    load();
  }, [code]);

  return (
    <>
      <Error error={error} />
      <div className="font-bold">Verify email address</div>
      <div>Please enter the verification code sent to your email address.</div>
      <VerificationInput
        value={code}
        autoFocus={true}
        placeholder={"-"}
        validChars="0-9"
        onChange={(c) => setCode(c)}
      />
      <Link className="underline text-sm" to="/change-user-email">
        Start over
      </Link>
    </>
  );
}
