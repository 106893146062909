import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { ModalHeader } from "../ModalHeader";

export function MemberList() {
  const user = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [memberships, setMemberships] = useState([]);
  const { getOrganizationMemberships } = useAuth();

  useEffect(() => {
    async function load() {
      try {
        setIsLoading(true);
        const json = await getOrganizationMemberships();
        setMemberships(json);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    load();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {memberships.map((membership) => (
        <div key={membership.user.id}>
          <div>{membership.user.email}</div>
        </div>
      ))}
      {user.membership.organization.personal && (
        <div className="text-xs text-gray-400">
          This is a personal organization and can only have you as a member.
          Please select or create a new organization if you want to add team
          members.
        </div>
      )}
      {!user.membership.organization.personal &&
        ["ADMIN", "OWNER"].includes(user.membership.role) && (
          <Link className="button" to="/invite-member">
            Invite people
          </Link>
        )}
    </>
  );
}

export function OrganizationMembers() {
  const user = useUser();
  return (
    <>
      <ModalHeader />
      <div className="font-bold">{user.membership.organization.name}</div>
      <div className="text-gray-400">Members</div>
      <MemberList />
    </>
  );
}
