import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../Button";
import { EmailInput } from "../EmailInput";
import { ModalHeader } from "../ModalHeader";
import { useUser } from "../../hooks/useUser";
import { useSaas } from "../context/SaasContext";
import { useNavigate } from "react-router-dom";

export function ChangeUserEmail() {
  const navigate = useNavigate();
  const { toast, setCode, setToken } = useSaas();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { sendEmailCode } = useAuth();

  async function submitHandler(e) {
    try {
      setCode("");
      setIsLoading(true);
      const { token } = await sendEmailCode({
        email,
      });
      setToken(token);
      setIsLoading(false);
      navigate("/verify-change-user-email");
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "save" });
    }
  }

  return (
    <>
      <ModalHeader />
      <div className="font-bold">Change email address</div>
      <div className="flex flex-col gap-1">
        <EmailInput
          placeholder={user.email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <Button isLoading={isLoading} onClick={submitHandler}>
        Continue
      </Button>
    </>
  );
}
