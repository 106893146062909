import { FAQ, AboutButtons } from "@ymse/saas";

export function About() {
  return (
    <div className="flex-1 flex flex-col gap-8 p-6">
      <h2 className="text-4xl font-bold leading-tight text-center">
        Calestimate gives you estimation superpowers!
      </h2>
      <AboutButtons />
      <FAQ
        items={[
          {
            q: "Can I share estimates with other team members?",
            a: "Yes, you can create a paid organization account and share your estimates to other members of that organization.",
          },
          {
            q: "How much does it cost?",
            a: "Individuals can use Calestimate for FREE with their Personal account. With a paid organization account you can share your estimates with other team members.",
          },
          {
            q: "How do you calculate the estimates?",
            a: "We use a modified PERT formula to calculate the estimates. PERT combines probability theory and statistics to derive a formula for the average (mean) activity duration from the three point estimates. Standard PERT uses 1-4-1 weightage i.e. it gives 4 times more weightage to the Likely estimate than the Optimistic and Pessimistic estimates. In our modified formula we give the Pessimistic estimate more weight if the risk is high.",
          },
          {
            q: "What does PERT stand for?",
            a: "PERT stands for Program Evaluation and Review Technique. PERT was developed by the U.S. Navy, Lockheed Martin, and Booz, Allen and Hamilton working on the Polaris nuclear submarine project in the 1950s",
          },
        ]}
      />
    </div>
  );
}
