import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../Button";
import { TextInput } from "../TextInput";
import { ModalHeader } from "../ModalHeader";
import { useUser } from "../../hooks/useUser";
import { useSaas } from "../context/SaasContext";

export function ChangeOrganizationName() {
  const { toast } = useSaas();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(user.membership.organization.name);
  const { updateOrganizationName } = useAuth();

  async function submitHandler(e) {
    try {
      setIsLoading(true);
      await updateOrganizationName({
        name,
      });
      setIsLoading(false);
      toast.success("Name changed!", { id: "save" });
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "save" });
    }
  }

  return (
    <>
      <ModalHeader />
      <div className="font-bold">Change organization name</div>
      <div className="flex flex-col gap-1">
        <TextInput value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <Button isLoading={isLoading} onClick={submitHandler}>
        Save changes
      </Button>
    </>
  );
}
