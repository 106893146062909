import { useSaas } from "../context/SaasContext";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { EmailInput } from "../EmailInput";
import { Button } from "../Button";

export function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [email, setEmail] = useState("");
  const { toast, setToken, setCode, privacyUrl, termsUrl, showPopupArticle } =
    useSaas();
  const { setSignupEmail } = useAuth();
  const navigate = useNavigate();

  const isConditions = privacyUrl || termsUrl;

  async function submitHandler(e) {
    try {
      if (isConditions && !isAccepted) {
        return alert("You must accept the conditions of use for this service.");
      }
      setIsLoading(true);
      const { token } = await setSignupEmail({
        email,
      });
      setIsLoading(false);
      setToken(token);
      setCode("");
      navigate("/verify-signup-email");
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "login" });
    }
  }

  return (
    <>
      <div className="font-bold">Create account</div>
      <EmailInput
        autoFocus
        placeholder="Your email address"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      {isConditions && (
        <div className="flex flex-col gap-2 text-sm">
          <div className="flex gap-2 items-center">
            <input
              checked={isAccepted}
              onChange={(e) => setIsAccepted(!isAccepted)}
              id="accepted"
              type="checkbox"
            />
            <label htmlFor="accepted" className="text-gray-500">
              I have read and accept the following:
            </label>
          </div>
          {termsUrl && (
            <div>
              <button
                onClick={() => showPopupArticle(termsUrl)}
                className="underline text-blue-500"
              >
                Terms of Service
              </button>
            </div>
          )}
          {privacyUrl && (
            <div>
              <button
                onClick={() => showPopupArticle(privacyUrl)}
                className="underline text-blue-500"
              >
                Privacy Policy
              </button>
            </div>
          )}
        </div>
      )}
      <Button isLoading={isLoading} onClick={submitHandler}>
        Continue
      </Button>
      <div className="flex flex-col -mx-4">
        <Link
          className="text-sm text-gray-600 hover:bg-gray-100 px-4 py-2"
          to="/login"
        >
          Log in
        </Link>
      </div>
    </>
  );
}
