import { useImmer } from "use-immer";
import { useThings } from "@ymse/saas";
import { TaskHeader } from "./TaskHeader";
import { RemoveIcon } from "./Icons";
import { TextInput } from "./TextInput";
import { NumberInput } from "./NumberInput";
import { RiskSelector } from "./RiskSelector";
import { CalcValue } from "./CalcValue";
import { roundWithPrecision } from "../../lib/math";
import { Result } from "./Result";
import { useState, useEffect } from "react";
import { retry } from "../../lib/retry";
import { useApi, useThings } from "@ymse/saas";
import { Button } from "@ymse/saas";
import { useNavigate } from "react-router-dom";
import { useSaas, Error } from "@ymse/saas";

const tasks = [
  {
    description: "",
    scopeOptimistic: 8,
    scopeMostLikely: 16,
    scopePessimistic: 24,
    riskComplex: 1,
    riskPersonel: 1,
    riskExperience: 1,
    riskReuse: 1,
    assumptions: [],
  },
];

export function Estimate({
  mutate,
  initialEstimate = {
    label: "",
    type: "estimate",
    data: {
      tasks,
    },
  },
}) {
  const [error, setError] = useState(null);
  const [lastChanged, setLastChanged] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [lastCalculated, setLastCalculated] = useState(0);
  const [calculated, setCalculated] = useState(null);
  const isCalculated = lastCalculated > lastChanged;
  const [estimate, updateEstimate] = useImmer(initialEstimate);
  const api = useApi();
  const { save, del } = useThings();
  const navigate = useNavigate();
  const { toast } = useSaas();

  // calculate the estimate on the server
  // so it's harder to reverse engineer
  useEffect(() => {
    const calculateEstimate = async () => {
      // only if it has changed or not been calculated yet
      if (!isCalculated && estimate) {
        const json = await api.post({
          path: `/calestimate/estimate`,
          body: JSON.stringify(estimate),
        });
        updateEstimate((draft) => {
          draft.data.tasks = json.estimate.data.tasks;
        });
        setCalculated(json.calculated);
        setLastCalculated(Date.now());
      }
    };
    retry(calculateEstimate, 5);
  }, [isCalculated, estimate]);

  const updateTaskValue = (i, key, value) => {
    updateEstimate((draft) => {
      draft.data.tasks[i][key] = value;
    });
    setLastChanged(Date.now());
  };

  const updateTaskDescription = (i, value) => {
    updateEstimate((draft) => {
      draft.data.tasks[i]["description"] = value;
    });
  };

  const updateName = (e) => {
    updateEstimate((draft) => {
      draft.label = e.target.value;
    });
  };

  const addTask = (e) => {
    updateEstimate((draft) => {
      draft.data.tasks.push({
        description: "",
        scopeOptimistic: 0,
        scopeMostLikely: 0,
        scopePessimistic: 0,
        riskComplex: 1,
        riskPersonel: 1,
        riskExperience: 1,
        riskReuse: 1,
      });
    });
    setLastChanged(Date.now());
  };

  const removeTask = (i, description) => {
    if (confirm(`Remove task? "${description}"`)) {
      updateEstimate((draft) => {
        draft.data.tasks.splice(i, 1);
      });
      setLastChanged(Date.now());
    }
  };

  const deleteHandler = async (e) => {
    setError(null);
    if (confirm("Delete this estimate?")) {
      setIsDeleting(true);
      try {
        await del({ thing: estimate });
        setIsDeleting(false);
        navigate("/dashboard");
      } catch (e) {
        setError(e);
        setIsDeleting(false);
      }
    }
  };

  const saveHandler = async (e) => {
    setError(null);
    setIsSaving(true);
    try {
      const result = await save({ thing: estimate });
      if (result) {
        if (mutate) {
          mutate();
        }
        updateEstimate((draft) => result);
      }
      setIsSaving(false);
    } catch (e) {
      setError(e);
      setIsSaving(false);
    }
  };

  if (!estimate) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 flex-1">
      <Error error={error} />
      <div className="flex items-center justify-between gap-4">
        <input
          className="py-2 focus:px-2 rounded flex-1 text-2xl"
          value={estimate.label}
          placeholder={"Project name..."}
          onChange={updateName}
        />
        <div className="flex gap-2 justify-end text-sm">
          <Button isLoading={isSaving} onClick={saveHandler}>
            Save
          </Button>
          {estimate.id && (
            <button
              disabled={isDeleting}
              className="button bg-white hover:bg-gray-100 border border-gray-400 text-black"
              onClick={deleteHandler}
            >
              Delete
            </button>
          )}
        </div>
      </div>
      <table className="border-collapse">
        <thead>
          <tr>
            <th className="border border-gray-500 p-2 bg-gray-100"></th>
            <th className="border border-gray-500 p-2 bg-gray-100">Task</th>
            <th className="border border-gray-500 p-2 bg-gray-100" colSpan={3}>
              Hours
            </th>
            <th className="border border-gray-500 p-2 bg-gray-100" colSpan={4}>
              Risk
            </th>
            <th className="border border-gray-500 bg-gray-100"></th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className="border border-gray-500"></th>
            <th className="border border-gray-500">
              <TaskHeader title="Description">
                Give the estimated task a clear description
              </TaskHeader>
            </th>
            <th className="border border-gray-500">
              <TaskHeader title="Optimistic">
                <div>Best case scenario, shortest time spent on the task.</div>
              </TaskHeader>
            </th>
            <th className="border border-gray-500">
              <TaskHeader title="Pessimistic">
                <div>Worst case scenario, longest time spent on the task.</div>
              </TaskHeader>
            </th>
            <th className="border border-gray-500">
              <TaskHeader title="Likely">
                <div>The most likely time spent on the task.</div>
              </TaskHeader>
            </th>
            <th className="border border-gray-500">
              <TaskHeader title="Complex">
                <div>How complex is the task?</div>
                <div>1 = Low complexity.</div>
                <div>5 = High complexity.</div>
              </TaskHeader>
            </th>
            <th className="border border-gray-500">
              <TaskHeader title="People">
                <div>How dependent are you of individuals to do the task?</div>
                <div>1 = Anyone in the team can do this task.</div>
                <div>
                  5 = Only one person has the experience and skill to do the
                  particular task.
                </div>
              </TaskHeader>
            </th>
            <th className="border border-gray-500">
              <TaskHeader title="Experience">
                <div>To what extent have we done similar tasks before?</div>
                <div>
                  1 = You have done this type of task many times before.
                </div>
                <div>5 = You have never done anything like this before.</div>
              </TaskHeader>
            </th>
            <th className="border border-gray-500">
              <TaskHeader title="Reuse">
                <div>
                  To what extent can we reuse previous work to complete the
                  task?
                </div>
                <div>1 = A very high degree of reuse.</div>
                <div>5 = No reuse, everything must be built from scratch. </div>
              </TaskHeader>
            </th>
            <th className="border border-gray-500">
              <TaskHeader title="Hours">
                Estimated hours on this task.
              </TaskHeader>
            </th>
          </tr>
        </thead>
        <tbody>
          {estimate.data.tasks.map((task, i) => {
            return (
              <tr key={`task-${i}`}>
                <td className="w-8 border border-gray-500 text-red-700 text-center">
                  <button
                    className="w-8 flex justify-center"
                    onClick={(e) => removeTask(i, task.description)}
                  >
                    <RemoveIcon />
                  </button>
                </td>
                <td className="border border-gray-500">
                  <TextInput
                    placeholder="Task description..."
                    value={task.description}
                    className="w-full"
                    onChange={(e) => updateTaskDescription(i, e.target.value)}
                  />
                </td>
                <td className="w-24 border border-gray-500">
                  <NumberInput
                    value={task.scopeOptimistic}
                    className="w-full text-center"
                    onChange={(e) =>
                      updateTaskValue(
                        i,
                        "scopeOptimistic",
                        parseInt(e.target.value) || 0
                      )
                    }
                  />
                </td>
                <td className="w-24 border border-gray-500">
                  <NumberInput
                    value={task.scopePessimistic}
                    className="w-full text-center"
                    onChange={(e) =>
                      updateTaskValue(
                        i,
                        "scopePessimistic",
                        parseInt(e.target.value) || 0
                      )
                    }
                  />
                </td>
                <td className="w-24 border border-gray-500">
                  <NumberInput
                    value={task.scopeMostLikely}
                    className="w-full text-center"
                    onChange={(e) =>
                      updateTaskValue(
                        i,
                        "scopeMostLikely",
                        parseInt(e.target.value) || 0
                      )
                    }
                  />
                </td>
                <td className="w-24 border border-gray-500">
                  <RiskSelector
                    value={task.riskComplex}
                    onChange={(e) =>
                      updateTaskValue(
                        i,
                        "riskComplex",
                        parseInt(e.target.value)
                      )
                    }
                  />
                </td>
                <td className="w-24 border border-gray-500">
                  <RiskSelector
                    value={task.riskPersonel}
                    onChange={(e) =>
                      updateTaskValue(
                        i,
                        "riskPersonel",
                        parseInt(e.target.value)
                      )
                    }
                  />
                </td>
                <td className="w-24 border border-gray-500">
                  <RiskSelector
                    value={task.riskExperience}
                    onChange={(e) =>
                      updateTaskValue(
                        i,
                        "riskExperience",
                        parseInt(e.target.value)
                      )
                    }
                  />
                </td>
                <td className="w-24 border border-gray-500">
                  <RiskSelector
                    value={task.riskReuse}
                    onChange={(e) =>
                      updateTaskValue(i, "riskReuse", parseInt(e.target.value))
                    }
                  />
                </td>
                <td className="w-24 border border-gray-500 font-bold">
                  <CalcValue>{roundWithPrecision(task.scopeHours)}</CalcValue>
                </td>
              </tr>
            );
          })}
          <tr>
            <td
              className="border border-gray-500 bg-gray-100 font-bold"
              colSpan={9}
            ></td>
            <td className="w-24 border border-gray-500 font-bold">
              <CalcValue>
                {roundWithPrecision(calculated ? calculated.scopeHoursSum : 0)}
              </CalcValue>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="-my-2">
        <button
          onClick={addTask}
          className="button text-black bg-white hover:bg-gray-100 text-sm"
        >
          + Add task
        </button>
      </div>
      <Result data={calculated} />
    </div>
  );
}
