import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../Button";
import { TextInput } from "../TextInput";
import { ModalHeader } from "../ModalHeader";
import { useUser } from "../../hooks/useUser";
import { useSaas } from "../context/SaasContext";

export function ChangeUserName() {
  const { toast } = useSaas();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const { updateUserName } = useAuth();

  async function submitHandler(e) {
    try {
      setIsLoading(true);
      await updateUserName({
        firstName,
        lastName,
      });
      setIsLoading(false);
      toast.success("Name changed!", { id: "save" });
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "save" });
    }
  }

  return (
    <>
      <ModalHeader />
      <div className="font-bold">Change name</div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">First name</label>
        <TextInput
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">Last name</label>
        <TextInput
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <Button isLoading={isLoading} onClick={submitHandler}>
        Save changes
      </Button>
    </>
  );
}
