import { useNavigate } from "react-router-dom";

export function ModalBack({ disabled = false }) {
  const navigate = useNavigate();
  return (
    <button
      className={`${
        disabled
          ? "opacity-25"
          : "opacity-100 hover:bg-gray-200 rounded-full py-1 px-2 -mx-2 -my-1"
      }`}
      disabled={disabled}
      onClick={(e) => navigate(-1)}
    >
      &larr; Back
    </button>
  );
}
