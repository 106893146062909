import { useSaas } from "./context/SaasContext";
import { ReactPortal } from "./ReactPortal";
import { useEffect } from "react";

export function Modal({ children }) {
  const { isModalOpen, hideModal } = useSaas();

  useEffect(() => {
    const closeOnEscapeKey = (e) =>
      e.key === "Escape" ? hideModal({ result: null }) : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, []);

  if (!isModalOpen) return null;

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <button
        onClick={() => hideModal({ result: null })}
        className="fixed inset-0 h-full w-full cursor-default bg-black opacity-30"
      ></button>
      <div className="absolute right-10 top-10 bg-white text-black rounded-lg border border-gray-300 shadow-xl p-4 w-96">
        {children}
      </div>
    </ReactPortal>
  );
}
