import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { ModalHeader } from "../ModalHeader";
import { useUser } from "../../hooks/useUser";
import { CopyToClipboard } from "react-copy-to-clipboard";

export function InviteCode() {
  const [token, setToken] = useState(null);
  const [copied, setCopied] = useState(false);
  const { getInviteOrganizationToken } = useAuth();
  useEffect(() => {
    async function load() {
      try {
        const result = await getInviteOrganizationToken();
        setToken(result.token);
      } catch (e) {}
    }
    load();
  }, []);

  if (!token) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-2">
        <div className="flex-1 p-2 rounded border border-gray-400 bg-gray-100">
          {token}
        </div>
        <CopyToClipboard text={token} onCopy={() => setCopied(true)}>
          <button className="button">Copy</button>
        </CopyToClipboard>
      </div>
      {copied && <div className="text-xs">Copied to clipboard...</div>}
    </div>
  );
}

export function InviteMember() {
  const user = useUser();
  return (
    <>
      <ModalHeader />
      <div className="font-bold">
        Invite people to {user.membership.organization.name}
      </div>
      <div className="text-sm">
        To accept this invite, the recipient must already have an account and be
        be logged in, change organization, select "Join new organization" and
        enter this code:
      </div>
      <InviteCode />
    </>
  );
}
