import { useState } from "react";

export function FAQ({ items }) {
  return (
    <div>
      <div className="border-b-2 font-bold border-black pb-2 mb-2">
        Frequently Asked Questions
      </div>
      {items.map((item, i) => (
        <Item key={`faq-${i}`} question={item.q} answer={item.a} />
      ))}
    </div>
  );
}

function Item({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b border-black pb-2 mb-2">
      <button
        onClick={(e) => setIsOpen(!isOpen)}
        className={`w-full hover:underline text-left ${
          isOpen ? "font-bold" : "font-normal"
        }`}
      >
        {question}
      </button>
      <div className={`${isOpen ? "block" : "hidden"}`}>{answer}</div>
    </div>
  );
}
