import { useThings, FullScreenSpinner } from "@ymse/saas";
import { Link } from "react-router-dom";
import { DateFormat } from "../DateFormat";

export function Estimates() {
  const { list } = useThings();

  const {
    things: result,
    isLoading,
    error,
  } = list({
    type: "estimate",
    options: {
      select: {
        id: true,
        label: true,
        createdAt: true,
      },
      orderBy: [
        {
          createdAt: "desc",
        },
      ],
    },
  });

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  if (error) {
    return;
  }

  return (
    <div className="flex-1 bg-gray-100 -m-4">
      <div className="container mx-auto max-w-screen-lg h-full pt-6">
        <div className="flex items-center justify-between px-4 mb-6">
          <div className="text-2xl">Estimates</div>
          <Link className="button text-sm" to="/">
            + New
          </Link>
        </div>
        {!result.data.length && (
          <div className="text-center py-4">No records found...</div>
        )}
        <div className="flex-1 bg-white mb-6 rounded">
          {result.data.map((estimate) => {
            return (
              <Link
                to={`/estimate/${estimate.id}`}
                key={`estimate-${estimate.id}`}
              >
                <div className="border-b p-2 px-4 flex items-center justify-between hover:bg-gray-50">
                  <div>{estimate.label}</div>
                  <div className="text-gray-400 text-sm">
                    <DateFormat>{estimate.createdAt}</DateFormat>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
