import { useUser } from "../hooks/useUser";
import { useSaas } from "./context/SaasContext";
import { Modal } from "./Modal";
import { About } from "./About";
import {
  MemoryRouter as Router,
  Routes,
  Route,
  Outlet,
  UNSAFE_LocationContext,
} from "react-router-dom";
import { PopupArticle } from "./PopupArticle";
import { Toaster } from "react-hot-toast";
import { Login } from "./modals/Login";
import { Profile } from "./modals/Profile";
import { Signup } from "./modals/Signup";
import { ForgotPassword } from "./modals/ForgotPassword";
import { VerifyForgotPassword } from "./modals/VerifyForgotPassword";
import { VerifySignupEmail } from "./modals/VerifySignupEmail";
import { SignupFinalize } from "./modals/SignupFinalize";
import { ChangePassword } from "./modals/ChangePassword";
import { CreateOrganization } from "./modals/CreateOrganization";
import { OrganizationMembers } from "./modals/OrganizationMembers";
import { InviteMember } from "./modals/InviteMember";
import { SelectOrganization } from "./modals/SelectOrganization";
import { JoinOrganization } from "./modals/JoinOrganization";
import { UserSettings } from "./modals/UserSettings";
import { OrganizationSettings } from "./modals/OrganizationSettings";
import { ChangeUserName } from "./modals/ChangeUserName";
import { ChangeUserEmail } from "./modals/ChangeUserEmail";
import { VerifyChangeUserEmail } from "./modals/VerifyChangeUserEmail";
import { ChangeUserPassword } from "./modals/ChangeUserPassword";
import { ChangeOrganizationName } from "./modals/ChangeOrganizationName";
import { DeleteAccount } from "./modals/DeleteAccount";
import { LeaveOrganization } from "./modals/LeaveOrganization";
import { DeleteOrganization } from "./modals/DeleteOrganization";

function Layout() {
  return (
    <div className="flex flex-col gap-4">
      <Outlet />
    </div>
  );
}

function PublicApp() {
  return (
    // https://github.com/remix-run/react-router/issues/7375#issuecomment-975431736
    <UNSAFE_LocationContext.Provider value={null}>
      <Router initialEntries={["/login"]} initialIndex={0}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" exact element={<Login />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route
              path="/verify-forgot-password"
              exact
              element={<VerifyForgotPassword />}
            />
            <Route
              path="/verify-signup-email"
              exact
              element={<VerifySignupEmail />}
            />
            <Route path="/signup-finalize" exact element={<SignupFinalize />} />
            <Route path="/change-password" exact element={<ChangePassword />} />
          </Route>
        </Routes>
      </Router>
    </UNSAFE_LocationContext.Provider>
  );
}

function PrivateApp() {
  return (
    // https://github.com/remix-run/react-router/issues/7375#issuecomment-975431736
    <UNSAFE_LocationContext.Provider value={null}>
      <Router initialEntries={["/profile"]} initialIndex={0}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/profile" exact element={<Profile />} />
            <Route
              path="/create-organization"
              exact
              element={<CreateOrganization />}
            />
            <Route
              path="/organization-members"
              exact
              element={<OrganizationMembers />}
            />
            <Route path="/invite-member" exact element={<InviteMember />} />
            <Route
              path="/select-organization"
              exact
              element={<SelectOrganization />}
            />
            <Route
              path="/join-organization"
              exact
              element={<JoinOrganization />}
            />
            <Route path="/user-settings" exact element={<UserSettings />} />
            <Route
              path="/organization-settings"
              exact
              element={<OrganizationSettings />}
            />
            <Route
              path="/change-user-name"
              exact
              element={<ChangeUserName />}
            />
            <Route
              path="/change-user-email"
              exact
              element={<ChangeUserEmail />}
            />
            <Route
              path="/verify-change-user-email"
              exact
              element={<VerifyChangeUserEmail />}
            />
            <Route
              path="/change-user-password"
              exact
              element={<ChangeUserPassword />}
            />
            <Route
              path="/change-organization-name"
              exact
              element={<ChangeOrganizationName />}
            />
            <Route path="/delete-account" exact element={<DeleteAccount />} />
            <Route
              path="/leave-organization"
              exact
              element={<LeaveOrganization />}
            />
            <Route
              path="/delete-organization"
              exact
              element={<DeleteOrganization />}
            />
          </Route>
        </Routes>
      </Router>
    </UNSAFE_LocationContext.Provider>
  );
}

function App() {
  const user = useUser();
  return user ? <PrivateApp /> : <PublicApp />;
}

function Button() {
  const { showModal } = useSaas();
  const user = useUser();
  return user ? (
    <button
      onClick={(e) => showModal()}
      className="bg-black border border-gray-50 text-white rounded-full h-9 w-9 text-sm flex items-center justify-center"
    >
      {user.initials}
    </button>
  ) : (
    <button
      onClick={(e) => showModal()}
      className="bg-white text-black border border-gray-400 rounded-full h-9 w-9 flex items-center justify-center"
    >
      <svg
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
      >
        <path
          d="M7.5 0a3.499 3.499 0 100 6.996A3.499 3.499 0 107.5 0zm-2 8.994a3.5 3.5 0 00-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 00-3.5-3.5h-4z"
          fill="currentColor"
        ></path>
      </svg>
    </button>
  );
}

export function UserButton() {
  return (
    <div className="relative">
      <Button />
      <Modal>
        <App />
      </Modal>
      <div className="text-sm">
        <Toaster />
      </div>
      <About />
      <PopupArticle />
    </div>
  );
}
