export function RiskSelector({ value, className, ...rest }) {
  return (
    <select
      value={value}
      {...rest}
      className={`bg-white text-right text-sm h-8 px-2 w-full ${className}`}
    >
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
      <option value={5}>5</option>
    </select>
  );
}
