import { InfoIcon } from "./Icons";
import ReactTooltip from "react-tooltip";
import ReactDOMServer from "react-dom/server";

export function TaskHeader({ title, children }) {
  return (
    <div className="flex items-center justify-center gap-2 text-black p-2 whitespace-nowrap">
      <div className="font-normal text-sm">{title}</div>
      {children && (
        <>
          <div
            className="cursor-pointer"
            data-tip={ReactDOMServer.renderToString(children)}
            data-html={true}
            data-class="text-left bg-red-200"
          >
            <InfoIcon />
          </div>
          <ReactTooltip />
        </>
      )}
    </div>
  );
}
