import { useSaas } from "../context/SaasContext";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import VerificationInput from "react-verification-input";
import { useAuth } from "../../hooks/useAuth";

export function VerifyForgotPassword() {
  const { token, toast, code, setCode } = useSaas();
  const { verifyCode } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function load() {
      if (code.length === 6) {
        try {
          await verifyCode({
            token,
            code,
          });
          navigate("/change-password");
        } catch (e) {
          toast.error(e.message, { id: "verify" });
        }
      }
    }
    load();
  }, [code]);

  return (
    <>
      <div className="font-bold">Verify email address</div>
      <div>Please enter the verification code sent to your email address.</div>
      <VerificationInput
        autoFocus={true}
        placeholder={"-"}
        validChars="0-9"
        onChange={(c) => setCode(c)}
      />
      <Link className="underline text-sm" to="/forgot-password">
        Try again
      </Link>
    </>
  );
}
