export function Error({ error }) {
  if (!error) {
    return null;
  }
  return (
    <div className="rounded text-white bg-red-700 px-4 py-2">
      {error.message || "Unknown error"}
    </div>
  );
}
