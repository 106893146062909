import { useSaas } from "../context/SaasContext";
import { useState } from "react";
import { Button } from "../Button";
import { useAuth } from "../../hooks/useAuth";
import { TextInput } from "../TextInput";
import { ModalHeader } from "../ModalHeader";
import { Error } from "../Error";

export function JoinOrganization() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [token, setToken] = useState("");
  const { validateInviteOrganizationToken, joinOrganization } = useAuth();
  const { toast, hideModal } = useSaas();

  async function validateTokenHandler(e) {
    try {
      setError(null);
      setIsLoading(true);
      const result = await validateInviteOrganizationToken({ token });
      setOrganization(result.organization);
      setIsLoading(false);
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  }

  async function joinHandler(e) {
    try {
      setIsLoading(true);
      await joinOrganization({ token });
      setIsLoading(false);
      toast.success(`You are now a member of ${organization.name}`);
      hideModal({ result: null });
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  }

  if (token && organization) {
    return (
      <>
        <ModalHeader />
        <div>Are you sure you want to join this organization?</div>
        <div className="font-bold">{organization.name}</div>
        <Error error={error} />
        <Button isLoading={isLoading} onClick={joinHandler}>
          Continue
        </Button>
      </>
    );
  }

  return (
    <>
      <ModalHeader />
      <Error error={error} />
      <div className="font-bold">Join new organization</div>
      <div>
        Please ask your organization owner for an invitation code if you don't
        have one.
      </div>
      <TextInput
        autoFocus
        placeholder="Invitation code"
        onChange={(e) => setToken(e.target.value)}
        value={token}
      />
      <Button isLoading={isLoading} onClick={validateTokenHandler}>
        Continue
      </Button>
    </>
  );
}
