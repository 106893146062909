import { useSaas } from "../context/SaasContext";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../Button";
import { PasswordInput } from "../PasswordInput";

export function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const { toast, token, code } = useSaas();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const { changePassword } = useAuth();
  const navigate = useNavigate();

  async function submitHandler(e) {
    try {
      setIsLoading(true);
      await changePassword({
        token,
        code,
        password,
        password2,
      });
      setIsLoading(false);
      toast.success("Password changed! Please log in", { id: "success" });
      navigate("/login");
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message, { id: "change-password" });
    }
  }

  return (
    <>
      <div className="font-bold">New password</div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">Password</label>
        <PasswordInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm">Repeat password</label>
        <PasswordInput
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </div>
      <Button isLoading={isLoading} onClick={submitHandler}>
        Change password
      </Button>
      <Link className="underline text-sm" to="/login">
        Log in
      </Link>
    </>
  );
}
